import React, {useEffect, useCallback} from "react"
import {connect} from "react-redux"
import {localSelector} from "../../../redux/selectors/localSelector"
import isEqual from "lodash/isEqual"
import DT from "duration-time-conversion"
import {getKeyCode} from "../../utils"

function getCurrentSubs(subs, beginTime, duration) {
    return subs.filter((item) => {
        return (
            (item.startTime >= beginTime && item.startTime <= beginTime + duration) ||
            (item.endTime >= beginTime && item.endTime <= beginTime + duration) ||
            (item.startTime < beginTime && item.endTime > beginTime + duration)
        )
    })
}

function magnetically(time, closeTime) {
    if (!closeTime) return time
    if (time > closeTime - 0.1 && closeTime + 0.1 > time) {
        return closeTime
    }
    return time
}

let lastTarget = null
let lastSub = null
let lastType = ""
let lastX = 0
let lastIndex = -1
let lastWidth = 0
let lastDiffX = 0
let isDroging = false

const TimeLine = React.memo(
    function ({player, subtitle, render, bounds, currentTime, checkSub, removeSub, hasSub, updateSub}) {
        const $blockRef = React.createRef()
        const currentSubs = getCurrentSubs(subtitle, render.beginTime, render.duration)
        const currentIndex = currentSubs.findIndex(
            (item) => item.startTime <= currentTime && item.endTime > currentTime,
        )

        const onMouseDown = useCallback((sub, event, type) => {
            lastSub = sub
            if (event.button !== 0) return
            isDroging = true
            lastType = type
            lastX = event.pageX - bounds.x
            lastIndex = currentSubs.indexOf(sub)
            lastTarget = $blockRef.current && $blockRef.current.children[lastIndex]
            lastWidth = lastTarget && parseFloat(lastTarget.style.width)
        }, [$blockRef, currentSubs, bounds])

        const onDoubleClick = useCallback((sub, event) => {
            const $subs = event.currentTarget
            const index = hasSub(sub)
            const previou = subtitle[index - 1]
            const next = subtitle[index + 1]
            if (previou && next) {
                const width = (next.startTime - previou.endTime) * (bounds.width / render.duration)
                $subs.style.width = `${width}px`
                const start = DT.d2t(previou.endTime)
                const end = DT.d2t(next.startTime)
                updateSub(sub, {
                    start,
                    end,
                })
            }
        }, [render.duration, bounds.width, subtitle, hasSub, updateSub])

        const onDocumentMouseMove = useCallback((event) => {
            if (isDroging && lastTarget) {
                lastDiffX = (event.pageX - bounds.x) - lastX
				const index = hasSub(lastSub)
				const previous = subtitle[index - 1]
				const next = subtitle[index + 1]
				let timeDiff = lastDiffX / (bounds.width / render.duration)
				if (lastType !== 'right') timeDiff = Math.max(timeDiff, (previous?.endTime || 0) - lastSub.startTime)
				if (lastType !== 'left') timeDiff = Math.min(timeDiff, (next?.startTime || player.duration) - lastSub.endTime)
				lastDiffX = timeDiff * (bounds.width / render.duration)
				if (lastType === "left") {
                    lastTarget.style.width = `${lastWidth - lastDiffX}px`
                    lastTarget.style.transform = `translate(${lastDiffX}px)`
                } else if (lastType === 'right') {
                    lastTarget.style.width = `${lastWidth + lastDiffX}px`
                } else {
                    lastTarget.style.transform = `translate(${lastDiffX}px)`
                }
            }
        }, [bounds, hasSub, subtitle, render.duration, player.duration])

        const onDocumentMouseUp = useCallback(() => {
            if (isDroging && lastTarget && lastDiffX) {
                const timeDiff = lastDiffX / (bounds.width / render.duration)
                const index = hasSub(lastSub)
                const previou = subtitle[index - 1]
                const next = subtitle[index + 1]

                const startTime = magnetically(lastSub.startTime + timeDiff, previou ? previou.endTime : null)
                const endTime = magnetically(lastSub.endTime + timeDiff, next ? next.startTime : null)
                const width = (endTime - startTime) * (bounds.width / render.duration)

                if ((previou && endTime < previou.startTime) || (next && startTime > next.endTime)) {
                    //
                } else {
                    if (lastType === "left") {
                        if (startTime >= 0 && lastSub.endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime)
                            updateSub(lastSub, { start })
                        } else {
                            lastTarget.style.width = `${width}px`
                        }
                    } else if (lastType === "right") {
                        if (endTime >= 0 && endTime - lastSub.startTime >= 0.2) {
                            const end = DT.d2t(endTime)
                            updateSub(lastSub, {end})
                        } else {
                            lastTarget.style.width = `${width}px`
                        }
                    } else {
                        if (startTime > 0 && endTime > 0 && endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime)
                            const end = DT.d2t(endTime)
                            updateSub(lastSub, {
                                start,
                                end,
                            })
                        } else {
                            lastTarget.style.width = `${width}px`
                        }
                    }
                }

                lastTarget.style.transform = `translate(0)`
            }

            lastType = ""
            lastX = 0
            lastWidth = 0
            lastDiffX = 0
            isDroging = false
        }, [render.duration, bounds.width, hasSub, subtitle, updateSub])

        const onKeyDown = useCallback(
            (event) => {
                const sub = currentSubs[lastIndex]
                if (sub && lastTarget) {
                    const keyCode = getKeyCode(event)
                    switch (keyCode) {
                        case 8:
                        case 46:
                            removeSub(sub)
                            break
                        default:
                            break
                    }
                }
            },
            [currentSubs, removeSub],
        )

        useEffect(() => {
            document.addEventListener("mousemove", onDocumentMouseMove)
            document.addEventListener("mouseup", onDocumentMouseUp)
            window.addEventListener("keydown", onKeyDown);
            return () => {
                document.removeEventListener("mousemove", onDocumentMouseMove)
                document.removeEventListener("mouseup", onDocumentMouseUp)
                window.removeEventListener("keydown", onKeyDown)
            }
        }, [onDocumentMouseMove, onDocumentMouseUp, onKeyDown])

        return (
            <>
            <div className="subplayer-timeline" ref={$blockRef}>
                {currentSubs.map((sub, key) => {
                    return (
                        <div
                        className={["sub-item", key === currentIndex ? "sub-highlight" : "", checkSub(sub) ? "sub-illegal" : "",].join(" ").trim()} key={key}
                        style={{
                            left: render.padding + (sub.startTime - render.beginTime) * (bounds.width / render.duration),
                            width: (sub.endTime - sub.startTime) * (bounds.width / render.duration),
                        }}
                        onClick={() => {
                            if (player.duration >= sub.startTime) {
                                player.currentTime = sub.startTime + 0.001
                            }
                        }}
                        onDoubleClick={(event) => onDoubleClick(sub, event)}>
							<div className="sub-handle" style={{left: 0, width: 10,}} onMouseDown={(event) => onMouseDown(sub, event, "left")}></div>
							<div className="sub-text" title={sub.text} onMouseDown={(event) => onMouseDown(sub, event)}>
								{`${sub.text}`.split(/\r?\n/).map((line, index) => (
									<p key={index}>{line}</p>
									))}
							</div>
							<div className="sub-handle" style={{right: 0, width: 10,}} onMouseDown={(event) => onMouseDown(sub, event, "right")}></div>
							<div className="sub-duration">{sub.duration}</div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    },
    (prevProps, nextProps) => {
        return (
            isEqual(prevProps.subtitle, nextProps.subtitle) &&
            isEqual(prevProps.render, nextProps.render) &&
            prevProps.currentTime === nextProps.currentTime &&
			prevProps.bounds.width === nextProps.bounds.width &&
			prevProps.bounds.x === nextProps.bounds.x
        )
    },
)

export default connect(localSelector)(TimeLine)
