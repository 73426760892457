import {connect} from "react-redux"
import {localSelector} from "../redux/selectors/localSelector"
import {useEffect} from "react"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Layout from "../components/Layout"
import Signin from "./Signin"
import SigninBouyges from "./SigninBouyges"
import Home from "./Home"
import Profile from "./Profile"
import Projects from "./Projects"
import Lexicons from "./Lexicons"
import NeurowideDashboard from "./NeurowideDashboard/NeurowideDashboard"
import ProjectDashboard from "./NeurowideDashboard/ProjectDashboard"
import TeamDashboard from "./NeurowideDashboard/TeamDashboard"
import TeamManagement from "./TeamManagement"
import Workflow from "./Workflow"
import Help from "./Help"
import i18n from "../i18n/i18n"

function AllRoutes({localState}) {

    useEffect(() => {
        i18n.changeLanguage(localState.lang === "fr-FR" ? "fr" : "en")
    }, [])

    return (
        <>
        <Router>
            <Layout>
                <Routes>
                    <Route path={"/signin"} element={<Signin/>}/>
                    <Route path={"/signin-bouygues"} element={<SigninBouyges/>}/>
                    <Route index element={<Home/>}/>
                    <Route path={"/profile"} element={<Profile/>}/>
                    <Route path={"/projects"} element={<Projects/>}/>
                    <Route path={"/lexicons"} element={<Lexicons/>}/>
                    <Route path={"/neurowide-dashboard/team/:teamID"} element={<TeamDashboard/>}/>
                    <Route path={"/neurowide-dashboard/team/:teamID/:projectID"} element={<ProjectDashboard/>}/>
                    <Route path={"/neurowide-dashboard"} element={<NeurowideDashboard/>}/>
                    <Route path={"/team-management"} element={<TeamManagement/>}/>
                    <Route path={"/help"} element={<Help/>}/>
                    <Route path={"/workflow/:projectID"} element={<Workflow/>}/>
                </Routes>
            </Layout>
        </Router>
    </>
  )
}

export default connect(localSelector)(AllRoutes)