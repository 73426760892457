import {useEffect} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import useTeam from "../../swrCustomHooks/useTeam"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import TeamUserCard from "../../components/team-user-card/TeamUserCard"
import AddTeamUsersForm from "../../forms/team-users/AddTeamUsersForm"

function TeamManagementContent({sessionState, REFRESH_COGNITO_TOKEN, isRender, forceUpdate}) {
    const {t} = useTranslation()
    const {team, mutateTeam, isLoadingTeam, isErrorTeam} = useTeam(sessionState.idTeam, sessionState.idToken)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateTeam()
    }, [isRender])

    if (isErrorTeam || isLoadingTeam || team.message || !team.team || !team.users) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION TEAM-MANAGEMENT-CONTENT">
            <h2>{t("Add a member")}</h2>
            <AddTeamUsersForm forceUpdate={forceUpdate}/>
            <h2>{`${t("Team members")} - ${team.team.name}`}</h2>
            <span>{team.users.length} {t("creators")}/ {team.team.max_users} {t("creators")}</span>
            <div className="users">
                {team.users.map((user, index) => (
                    <TeamUserCard key={`user-${index}`} user={user} idAdmin={team.team.id_admin} type={"team-management"}/>
                ))}
            </div>
        </div>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(TeamManagementContent)