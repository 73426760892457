import {useState, useReducer, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import {NW_DASHBOARD_SET_TEAM_PAGE} from "../../redux/actions/neurowideDashboardActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useParams, useNavigate} from "react-router-dom"
import useTeam from "../../swrCustomHooks/useTeam"
import useTeamOverview from "../../swrCustomHooks/useTeamOverview"
import useTeamHistory from "../../swrCustomHooks/useTeamHistory"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import TeamDashboardHeader from "../../sections/team-dashboard-header/TeamDashboardHeader"
import TeamDashboardContent from "../../sections/team-dashboard-content/TeamDashboardContent"
import NeurowideDashboardModal from "../../sections/neurowide-dashboard-modal/NeurowideDashboardModal"
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import RequireIsAdmin from "../../tools/RequireIsAdmin"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import ScrollingMod from "../../tools/ScrollingMod"
import TeamDashboardFooter from "../../sections/team-dashboard-footer/TeamDashboardFooter"

function TeamDashboard({sessionState, neurowideDashboardState, REFRESH_COGNITO_TOKEN, NW_DASHBOARD_SET_TEAM_PAGE}) {
    const {t} = useTranslation()
    let params = useParams()
    const navigate = useNavigate()
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const {team, mutateTeam, isLoadingTeam, isErrorTeam} = useTeam(params.teamID, sessionState.idToken)
    const {teamOverview, mutateTeamOverview, isLoadingTeamOverview, isErrorTeamOverview} = useTeamOverview(params.teamID, sessionState.idToken)
    const {teamHistory, mutateTeamHistory, isLoadingTeamHistory, isErrorTeamHistory} = useTeamHistory(params.teamID, sessionState.idToken, neurowideDashboardState.teamPage)
    ScrollingMod(neurowideDashboardState.isModal)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateTeam()
        mutateTeamOverview()
        mutateTeamHistory()
        NW_DASHBOARD_SET_TEAM_PAGE(1)
        if (deleteConfirmation) {
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.delete(`${API_TEAMS}/${neurowideDashboardState.isConfirmationModal.id}`, headersRequest)
            .then(() => {
                setDeleteConfirmation(false)
                forceUpdate()
                navigate("/neurowide-dashboard")
                toast.success(t("Licence removed"))
            })
            .catch((error) => {
                toast.error(t("This team still has projects and cannot be disable"))
                console.error("DELETE /teams/team: ", error)
            })
        }
    }, [isRender, deleteConfirmation])

    if (isErrorTeam || isLoadingTeam || team.message
        || isErrorTeamOverview || isLoadingTeamOverview || teamOverview.message
        || isErrorTeamHistory || isLoadingTeamHistory || teamHistory.message) return <LoadingComponent/>
    return (
        <>
        <RequireIsAdmin role={sessionState.role} redirect={true}>
            <div className="VIEW TEAM">
                <NeurowideDashboardModal forceUpdate={forceUpdate} team={team} type={"update"}/>
                {neurowideDashboardState.isConfirmationModal.modal && <ConfirmationModal type={"team"} setDeleteConfirmation={setDeleteConfirmation}/>}
                <TeamDashboardHeader team={teamOverview.team}/>
                <TeamDashboardContent teamOverview={teamOverview} adminID={team.team.id_admin} teamHistory={teamHistory}/>
                <TeamDashboardFooter isRender={isRender} teamID={params.teamID}/>
            </div>
        </RequireIsAdmin>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN, NW_DASHBOARD_SET_TEAM_PAGE})(TeamDashboard)