import axios from "axios"
import useSWR from "swr"
import {API_TEAMS} from "../constants/apiUrls"

export default function useTeamHistory(teamID, tokenID, teamPage) {
    const url = `${API_TEAMS}/${teamID}/history` + (teamPage ? `?limit=8&offset=${teamPage === 1 ? 0 : ((teamPage - 1) * 8)}` : "")

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: url, token: tokenID}, fetcher)

    return {
        teamHistory: data,
        mutateTeamHistory: mutate,
        isLoadingTeamHistory: !error && !data,
        isErrorTeamHistory: error
    }
}