import {useEffect} from "react"
import {connect} from "react-redux"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {NW_DASHBOARD_SET_PROJECT_PAGE} from "../../redux/actions/neurowideDashboardActions"
import useCountProjectTasks from "../../swrCustomHooks/useCountProjectTasks"
import {useTranslation} from "react-i18next"
import arrowLeftBlackIcon from "../../assets/icon/arrow-left-black.svg"
import arrowLeftGrey from "../../assets/icon/arrow-left-grey.svg"
import arrowRightBlackIcon from "../../assets/icon/arrow-right-black.svg"
import arrowRightGrey from "../../assets/icon/arrow-right-grey.svg"

function ProjectDashboardFooter({sessionState, neurowideDashboardState, NW_DASHBOARD_SET_PROJECT_PAGE, projectID}) {
    const {t} = useTranslation()
    const {countProjectTasks, mutateCountProjectTasks, isLoadingCountProjectTasks, isErrorCountProjectTasks} = useCountProjectTasks(sessionState.idToken, projectID)
    const options = []

    try {
        var index = 1
        while (index < (Math.ceil(countProjectTasks / 8) + 1)) {
            options.push({value: index, label: index})
            index++
        }
    } catch {}

    const handlePage = (value) => {
        const selectPage = document.getElementById("selectPage")
        if (value === "-" && neurowideDashboardState.projectPage > 1) {
            selectPage.value = parseInt(neurowideDashboardState.projectPage - 1)
            NW_DASHBOARD_SET_PROJECT_PAGE(parseInt(neurowideDashboardState.projectPage) - 1)
        } else if (value === "+" && neurowideDashboardState.projectPage < Math.ceil(countProjectTasks / 8)) {
            selectPage.value = parseInt(neurowideDashboardState.projectPage + 1)
            NW_DASHBOARD_SET_PROJECT_PAGE(parseInt(neurowideDashboardState.projectPage) + 1)
        }
    }

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateCountProjectTasks()
    }, [])

    if (isErrorCountProjectTasks || isLoadingCountProjectTasks || countProjectTasks.message || countProjectTasks <= 8) return ""
    return (
        <>
        <div className="SECTION PROJECT-DASHBOARD-FOOTER">
            <div className="left-content">
                <select id="selectPage" name="selectPage" onChange={(e) => NW_DASHBOARD_SET_PROJECT_PAGE(parseInt(e.target.value))}>
                    {options.map((option, index) => {
                        if ((index + 1) === neurowideDashboardState.projectPage) {
                            return <option selected key={`option-${index}`} value={option.value}>{option.label}</option>
                        } else {
                            return <option key={`option-${index}`} value={option.value}>{option.label}</option>
                        }
                    })}
                </select>
                <p>sur {Math.ceil(countProjectTasks / 8)} page{Math.ceil(countProjectTasks / 8) >= 2 && "s"}</p>
            </div>
            <div className="right-content">
                <img src={(Math.ceil(countProjectTasks / 8) === 1 || neurowideDashboardState.projectPage === 1) ? arrowLeftGrey : arrowLeftBlackIcon} alt="" onClick={() => handlePage("-")} title={t("Previous page")}/>
                <img src={(Math.ceil(countProjectTasks / 8) === 1 || neurowideDashboardState.projectPage === Math.ceil(countProjectTasks / 8)) ? arrowRightGrey : arrowRightBlackIcon} alt="" onClick={() => handlePage("+")} title={t("Next page")}/>
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {NW_DASHBOARD_SET_PROJECT_PAGE})(ProjectDashboardFooter)