import {useState, useEffect, useMemo} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import {IS_MODAL} from "../../redux/actions/neurowideDashboardActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import useUser from "../../swrCustomHooks/useUser"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import Select from "react-select"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import {toast} from "react-toastify"
import {labelMap} from "../../constants/multilingual"

function UpdateTeamForm({sessionState, REFRESH_COGNITO_TOKEN, IS_MODAL, team, forceUpdate}) {
    const {t} = useTranslation()
    const {user, mutateUser, isLoadingUser, isErrorUser} = useUser(team.team.id_admin, sessionState.idToken)
    const [selectValue, setSelectValue] = useState(team.team.id_admin)
    const [languages, setLanguages] = useState(team.team.languages)
    const [isLanguages, setIsLanguages] = useState(true)
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {
        name: team.team.name,
        maxUsers: team.team.max_users,
        nbCredits: team.team.nb_credits,
        maxTranscription: team.team.max_transcription_per_project,
        maxTraslation: team.team.max_translation_per_project,
        maxDuration: team.team.max_duration,
        licenceStartDate: team.team.licence_start_date.split("T")[0],
        licenceEndDate: team.team.licence_end_date.split("T")[0],
        urlLabel: team.team.licence_package.url_label,
        url: team.team.licence_package.url
    }})

    const teamManagerOptions = []
    try {
        for (let teamUser of team.users) {
            teamManagerOptions.push({value: teamUser.id, label: teamUser.email})
        }
    } catch {}

    const transformLabelMap = (labelMap) => {
        return Object.entries(labelMap).map(([code, label]) => ({
            value: code,
            label: label,
        }))
    }

    const transformLanguages = useMemo(() => {
        return languages
            .map((lang) => ({
                value: lang.code,
                label: labelMap[lang.code],
            }))
    }, [languages, labelMap])

    const handleRequest = async (data) => {
        if (languages.length > 1) {
            setIsLanguages(true)
            let languagesValues = []
            for (let element of languages) {
                languagesValues.push(element.value || element.code)
            }
            const bodyRequest = {
                name: data.name,
                licence_start_date: data.licenceStartDate,
                licence_end_date: data.licenceEndDate,
                licence_package: {url_label: data.urlLabel || "", url: data.url || ""},
                max_users: parseInt(data.maxUsers),
                nb_credits: parseInt(data.nbCredits),
                max_transcription_per_project: parseInt(data.maxTranscription),
                max_translation_per_project: parseInt(data.maxTraslation),
                max_duration: parseInt(data.maxDuration),
                languages: languagesValues,
                id_admin: selectValue.value,
            }
            currentAuthSession().then((response) => {
                REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.put(`${API_TEAMS}/${team.team.id}`, bodyRequest, headersRequest)
            .then(() => {
                forceUpdate()
                IS_MODAL(false)
                toast.success(t("Changes made"))
            }).catch((error) => {
                console.error("PUT /teams/team: ", error)
            })
        } else {
            setIsLanguages(languages.length <= 1 ? false : true)
        }
    }

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateUser()
    }, [])

    if (isErrorUser || isLoadingUser || !user) return <LoadingComponent/>
    return (
        <>
        <form className="update-team-form" onSubmit={handleSubmit(handleRequest)}>
            <div className="category">
                <h3>{t("LICENCE")}</h3>
                <div className="field">
                    <label for="">{t("Licence name")}*</label>
                    <input type="text" id="name" name="name" placeholder={t("Licence name")} {...register("name", {required: true, minLength: 2})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of creators")}*</label>
                    <input type="number" id="max-users" name="max-users" placeholder={t("Ex. Project 01")} min="1" {...register("maxUsers", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Start")}*</label>
                    <input type="date" id="licence-start-date" name="licence-start-date" placeholder="" {...register("licenceStartDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("End")}*</label>
                    <input type="date" id="licence-end-date" name="licence-end-date" placeholder="" {...register("licenceEndDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">Team manager*</label>
                    <Select className="select" options={teamManagerOptions} defaultValue={{value: user.id, label: user.email}} onChange={(value) => setSelectValue(value)}/>
                </div>
            </div>
            <div className="category">
                <h3>{t("CHARACTERISTICS")}</h3>
                <div className="field">
                    <label for="">{t("Credits")}*</label>
                    <input type="number" id="nb-credits" name="nb-credits" placeholder="Ex. 10" min="1" {...register("nbCredits", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Max audio / video duration")}*</label>
                    <input type="number" id="max-duration" name="max-duration" placeholder="Ex. 15 min" min="1" {...register("maxDuration", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of transcriptions allowed")}*</label>
                    <input type="number" id="max-transcription" name="max-transcription" placeholder="Ex. 03" min="1" {...register("maxTranscription", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of translations allowed")}*</label>
                    <input type="number" id="max-traslation" name="max-traslation" placeholder="Ex. 03" min="1" {...register("maxTraslation", {required: true})}/>
                </div>
                <label>{`${t("Team Languages")}*`}</label>
                <Select
                    defaultValue={transformLanguages}
                    placeholder={t("Select languages")}
                    isMulti
                    name="team-languages"
                    options={transformLabelMap(labelMap)}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isSearchable={false}
                    className={`basic-multi-select ${!isLanguages && "select-not-valid"}`}
                    classNamePrefix="select"
                    onChange={(value) => setLanguages(value || [])}
                />
                <h3>{t("package")}</h3>
                <div className="field">
                    <label for="">{t("Url label")}</label>
                    <input  className={`${errors.urlLabel ? "not-valid" : ""}`} type="text" id="urlLabel" name="urlLabel" placeholder="Ex. Nolej"  {...register("urlLabel", {minLength: 2})}/>
                </div>
                <div className="field">
                    <label for="">{t("Url")}</label>
                    <input className={`${errors.url ? "not-valid" : ""}`} type="text" id="url" name="url" placeholder="Ex. https://..." {...register("url", {pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/})}/>
                </div>
            </div>
            <button>{t("Update")}</button>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN, IS_MODAL})(UpdateTeamForm)