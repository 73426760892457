
import {useState, useRef} from "react"
import TeamUserCard from "../../components/team-user-card/TeamUserCard"
import {useTranslation} from "react-i18next"
import arrowRightBlackIcon from "../../assets/icon/arrow-right-black.svg"

export default function UsersAccordion({users, adminID, type}) {
    const {t} = useTranslation()
    const contentHeight = useRef()
    const [isOpen, setIsOpen] = useState(null)

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    if (!users) return ""
    return (
        <>
        <div className="users-accordion">
            <div className="header" style={{borderRadius: isOpen && "10px 10px 0px 0px"}} onClick={handleClick}>
                <h3><span>{users.length}</span> {t("members")}</h3>
                <img className={isOpen && "rotate"} src={arrowRightBlackIcon} alt=""/>
            </div>
            <div ref={contentHeight} className="content" style={{maxHeight: isOpen ? contentHeight.current.scrollHeight : "0px", padding: isOpen && "10px"}}>
                {users.map((user, index) => (
                    <TeamUserCard key={`user-${index}`} user={user} idAdmin={adminID} type={type}/>
                ))}
            </div>
        </div>
        </>
    )
}