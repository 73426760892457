
import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useCountProjectTasks(tokenID, projectID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_PROJECTS}/${projectID}/history/count`, token: tokenID}, fetcher)

    return {
        countProjectTasks: data,
        mutateCountProjectTasks: mutate,
        isLoadingCountProjectTasks: !error && !data,
        isErrorCountProjectTasks: error
    }
}