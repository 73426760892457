import {useState, useReducer, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import axios from "axios"
import {API_PROJECTS} from "../constants/apiUrls"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import ConfirmationModal from "../components/confirmation-modal/ConfirmationModal"
import ProjectsModal from "../sections/projects-modal/ProjectsModal"
import HomeHeader from "../sections/home-header/HomeHeader"
import HomeContent from "../sections/home-content/HomeContent"
import ScrollingMod from "../tools/ScrollingMod"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"

function Home({projectsState, sessionState}) {
    const {t} = useTranslation()
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    ScrollingMod(projectsState.isModal)
    let navigate = useNavigate()

    useEffect(() => {
        sessionState.role === "admin" && navigate("/neurowide-dashboard")
        if (deleteConfirmation) {
            currentAuthSession().then((response) => {
                REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.delete(`${API_PROJECTS}/${projectsState.isConfirmationModal.id}`, headersRequest)
            .then(() => {
                toast.success(t("Project deleted"))
                setDeleteConfirmation(false)
                forceUpdate()
            })
            .catch((error) => {
                console.error(`DELETE ${API_PROJECTS}/${projectsState.isConfirmationModal.id}: `, error)
                toast.error(t("An error has occurred"))
                setDeleteConfirmation(false)
                forceUpdate()
            })
        }
    }, [deleteConfirmation])

    return (
        <>
        <div className="VIEW HOME">
            {projectsState.isConfirmationModal.modal && <ConfirmationModal type={"projects"} setDeleteConfirmation={setDeleteConfirmation}/>}
            {projectsState.isModal && <ProjectsModal forceUpdate={forceUpdate}/>}
            <HomeHeader/>
            <span className="line"></span>
            <HomeContent isRender={isRender}/>
        </div>
        </>
    )
}

export default connect(globalSelector)(Home)