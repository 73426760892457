import UsersAccordion from "../../components/users-accordion/UsersAccordion"
import InformationPanel from "../../components/information-panel/InformationPanel"
import DashboardTable from "../../components/dashboard-table/DashboardTable"

export default function TeamDashboardContent({teamOverview, adminID, teamHistory}) {
    return (
        <>
        <div className="SECTION TEAM-DASHBOARD-CONTENT">
            <UsersAccordion users={teamOverview.users} adminID={adminID} type={"team-dashboard"}/>
            <InformationPanel overview={teamOverview.team} type={"licence"}/>
            <DashboardTable teamID={teamOverview.team.id} type={"licence"} history={teamHistory}/>
        </div>
        </>
    )
}