import axios from "axios"
import useSWR from "swr"
import {API_TEAMS} from "../constants/apiUrls"

export default function useCountTeamTasks(tokenID, teamID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: `${API_TEAMS}/${teamID}/history/count`, token: tokenID}, fetcher)

    return {
        countTeamTasks: data,
        mutateCountTeamTasks: mutate,
        isLoadingCountTeamTasks: !error && !data,
        isErrorCountTeamTasks: error
    }
}