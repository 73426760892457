import {useState, useEffect, useMemo} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {WORKFLOW_IS_CONFIRMATION_MODAL} from "../../redux/actions/worfklowActions"
import {toast} from "react-toastify"
import Select from "react-select"
import {labelMap} from "../../constants/multilingual"

function WorkflowRender({sessionState,
    projectsState,
    project,
    destinationLanguage,
    langCode,
    exportConfirmation,
    setExportConfirmation,
    WORKFLOW_IS_CONFIRMATION_MODAL}) {
    const {t} = useTranslation()
    const [videoUrl, setVideoUrl] = useState([])
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
    const [SRTLang, setSRTLang] = useState(project.lang_code)

    const allLanguages = useMemo(() => {
        let destinationLanguageTmp = destinationLanguage.map((lang) => ({
            value: lang.code,
            label: labelMap[lang.code],
        }))
        destinationLanguageTmp.push({
            value: langCode,
            label: labelMap[langCode],
        })
        return destinationLanguageTmp
    }, [destinationLanguage, langCode, labelMap])

    const getVideoURL = (codeISO) => {
        axios.get(`${API_PROJECTS}/${projectsState.projectID}/transcription`, headersRequest, {maxRedirects: 0})
        .then(() => {
            axios.get(`${API_PROJECTS}/${projectsState.projectID}/exports/${codeISO}`, headersRequest, {maxRedirects: 0})
            .then((response) => {
                setVideoUrl(response.data)
            })
            .catch((error) => {
                console.error("GET /projects/project/exports/fr: ", error)
            })
        })
        .catch((error) => {
            console.error("GET /projects/project/transcription: ", error)
        })
    }

    const getArchives = async () => {
        await axios.get(`${API_PROJECTS}/${projectsState.projectID}/archive.zip`, headersRequest, {maxRedirects: 0})
        .then((response) => {
            var link = document.createElement("a")
            link.href = response.data
            link.click()
            toast.success(t("Your project has been exported!"))
        })
        .catch(() => {
            toast.error(t("An error has occurred"))
        })
    }

    const exportProject = () => {
        WORKFLOW_IS_CONFIRMATION_MODAL({modal: true, id: projectsState.projectID})
    }

    useEffect(() => {
        if (exportConfirmation) {
            getArchives()
        } else {
            getVideoURL(SRTLang)
        }
        setExportConfirmation(false)
    }, [SRTLang, exportConfirmation])

    return (
        <>
        <div className="SECTION WORKFLOW-RENDER">
            <div className="header">
            <div className="header-content">
                    <p>{t("Language")} :</p>
                    <Select
                    defaultValue={{
                        value: langCode,
                        label: labelMap[langCode],
                    }}
                    name="languages"
                    options={allLanguages}
                    isClearable={false}
                    isSearchable={false}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(value) => {
                        setSRTLang(value.value)
                    }}/>
                </div>
            </div>
            <video controls crossOrigin="anonymous" src={videoUrl} type="video/mp4"></video>
            {project.archive_status === "done" ?
                <>
                <button className="export-enable" onClick={project.finished ? getArchives : exportProject} title={t("Export")}>{t("Export")}</button>
                </>
                :
                <>
                <button className="export-disable" title={t("Export")}>{t("Export")}</button>
                </>
            }
        </div>
        </>
    )
}

export default connect(globalSelector, {WORKFLOW_IS_CONFIRMATION_MODAL})(WorkflowRender)