import {connect} from "react-redux"
import {neurowideDashboardSelector} from "../../redux/selectors/neurowideDashboardSelector"
import {IS_MODAL} from "../../redux/actions/neurowideDashboardActions"
import AddTeamForm from "../../forms/teams/AddTeamForm"
import UpdateTeamForm from "../../forms/teams/UpdateTeamForm"
import closeIcon from "../../assets/icon/close.svg"
import {useTranslation} from "react-i18next"

function NeurowideDashboardModal({neurowideDashboardState, IS_MODAL, forceUpdate, team, type}) {
    const {t} = useTranslation()
    return (
        <>
        <div className={`neurowide-dashboard-modal ${neurowideDashboardState.isModal && "open"}`}>
            <h2>{type === "add" ? `${t("New licence")}` : team.team.name}</h2>
            <img className="close" src={closeIcon} alt="" onClick={() => IS_MODAL(false)}/>
            {type === "add" ?
                <><AddTeamForm IS_MODAL={IS_MODAL} forceUpdate={forceUpdate}/></>
                :
                <><UpdateTeamForm team={team} forceUpdate={forceUpdate}/></>
            }
        </div>
        </>
    )
}

export default connect(neurowideDashboardSelector, {IS_MODAL})(NeurowideDashboardModal)