import {useState, useMemo} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import Dropzone from "../../components/dropzone/Dropzone"
import {toast} from "react-toastify"
import useTeam from "../../swrCustomHooks/useTeam"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import Select from "react-select"
import {labelMap} from "../../constants/multilingual"

function ProjectsModalForm({sessionState, IS_MODAL, REFRESH_COGNITO_TOKEN, forceUpdate, setIsUpload}) {
    const {t} = useTranslation()
    const {team, isLoadingTeam, isErrorTeam} = useTeam(sessionState.idTeam, sessionState.idToken)
    const languages = team?.team?.languages || []
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {isPrivate: true}})

    const allLanguages = useMemo(() => {
        return languages
            .map((lang) => ({
                value: lang.code,
                label: labelMap[lang.code],
            }))
    }, [languages, labelMap])

    const [sourceLanguage, setSourceLanguage] = useState()
    const [isSourceLanguage, setIsSourceLanguage] = useState(true)
    const [destinationLanguages, setDestinationLanguages] = useState([])
    const [isDestinationLanguages, setIsDestinationLanguages] = useState(true)
    const [uploadFile, setUploadFile] = useState()

    const filteredSourceOptions = useMemo(() => {
        return allLanguages.filter(
            (lang) => !destinationLanguages.some((destLang) => destLang.value === lang.value)
        )
    }, [allLanguages, destinationLanguages])

    const filteredDestinationOptions = useMemo(() => {
        return allLanguages.filter((lang) => lang.value !== sourceLanguage?.value)
    }, [allLanguages, sourceLanguage])

    const createProject = async (data) => {
        if (sourceLanguage !== undefined && destinationLanguages.length > 0) {
            setIsSourceLanguage(true)
            setIsDestinationLanguages(true)
            let destinationLanguagesValues = []
            for (let element of destinationLanguages) {
                destinationLanguagesValues.push(element.value)
            }
            const bodyRequest = {
                is_public: !data.isPrivate,
                title: data.title,
                lang_code: sourceLanguage.value,
                destination_languages: destinationLanguagesValues,
            }
            currentAuthSession().then((response) => {
                REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            if (uploadFile) {
                await axios.post(API_PROJECTS, bodyRequest, headersRequest)
                .then((response) => {
                    setIsUpload(true)
                    axios.post(`${API_PROJECTS}/${response.data.id}/tries`, {step: "upload", filename: uploadFile.name},  headersRequest)
                    .then((response) => {
                        axios.put(response.data.uploadUrl, uploadFile)
                        .then(() => {
                            setIsUpload(false)
                            IS_MODAL(false)
                            forceUpdate()
                            toast.success(t("Upload completed!"))
                        })
                        .catch(() => {
                            toast.error(t("An error has occurred"))
                        })
                    })
                    .catch((error) => {
                        console.error("PUT /uploadUrl/file: ", error)
                    })
                })
                .catch((error) => {
                    switch (JSON.parse(error.request.response).message) {
                        case "Your Team has no enough credits to perform this action":
                            console.error("POST  /projects: ", error)
                            toast.error(t("Not enough credit. Please liaise with the Neurowide sales department."))
                            break
                        default:
                            toast.error(t("An error has occurred"))
                    }
                })
            }
        } else {
            setIsSourceLanguage(sourceLanguage === undefined ? false : true)
            setIsDestinationLanguages(destinationLanguages.length === 0 ? false : true)
        }
    }

    const closeModal = () => {
        IS_MODAL(false)
    }

    if (isErrorTeam || isLoadingTeam || team.message) return <LoadingComponent/>
    return (
        <>
        <form className="projects-modal-form">
            <div className="left-content">
                <h3>{t("Project information")}</h3>
                <span className="switch-label">{t("Private")}</span>
                <label className="switch" title={t("Manage project visibility")}>
                    <input type="checkbox" id="is-private" name="is-private" {...register("isPrivate", {type: Boolean})}/>
                    <span className="slider round"></span>
                </label>
                <div className="field">
                    <label>{`${t("Project name")}*`}</label>
                    <input className={`${errors.title ? "not-valid" : ""}`} type="text" id="title" name="title" placeholder="Ex. Projet 01" {...register("title", {required: true, minLength: 2, maxLength: 75})}/>
                </div>
                <label>{`${t("Source language")}*`}</label>
                <Select
                    name="source-language"
                    options={filteredSourceOptions}
                    isClearable={false}
                    isSearchable={false}
                    className={`basic-single ${!isSourceLanguage && "select-not-valid"}`}
                    classNamePrefix="select"
                    onChange={(value) => setSourceLanguage(value)}
                />
                <label>{`${t("Destination languages")}*`}</label>
                <Select
                    placeholder={t("Select destination languages")}
                    isMulti
                    name="destination-languages"
                    options={filteredDestinationOptions}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isSearchable={false}
                    className={`basic-multi-select ${!isDestinationLanguages && "select-not-valid"}`}
                    classNamePrefix="select"
                    onChange={(value) => setDestinationLanguages(value || [])}
                />
                <span className="mandatory">{`*${t("Required fields")}`}</span>
            </div>
            <div className="right-content">
                <Dropzone setUploadFile={setUploadFile}
                maxSize={500000000}
                maxDuration={team.team.max_duration * 60}/>
            </div>
            <div className="buttons">
                <button className="cancel-btn" onClick={() => closeModal()} title={t("Cancel")}>{t("Cancel")}</button>
                <button className="create-project-btn" onClick={handleSubmit(createProject)} title={t("Create")}>{t("Create")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(ProjectsModalForm)