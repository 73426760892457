import {useTranslation} from "react-i18next"

export default function InformationPanel({overview, type}) {
    const {t} = useTranslation()
    return (
        <>
        <div className="information-panel">
            {type === "licence" &&
                <>
                <div className="information">
                    <p><span>{overview.nb_initial_credits}</span> {t("Credit base")}</p>
                </div>
                <div className="information">
                    <p><span>{overview.nb_credits}</span> {t("Remaining credits")}</p>
                </div>
                </>
            }
            <div className="information">
                <p><span>{overview.transcription_overage_quota}</span> {t("Transcription quota")}</p>
            </div>
            <div className="information">
                <p><span>{overview.translation_overage_quota}</span> {t("Translation quota")}</p>
            </div>
            <div className="information">
                <p><span>{overview.total_tasks_launched.total}</span> Actions</p>
            </div>
        </div>
        </>
    )
}