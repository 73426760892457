import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import arrowLeftIcon from "../../assets/icon/arrow-left.svg"

export default function ProjectDashboardHeader({projectOverview}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const previousPage = () => {
        navigate(-1)
    }

    return (
        <>
        <div className="SECTION PROJECT-DASHBOARD-HEADER">
            <span>
                <h1>{projectOverview.project.licence_name}</h1>
            </span>
            <button className="previous-btn" onClick={previousPage} title={t("Previous page")}><img src={arrowLeftIcon} alt=""/></button>
        </div>
        </>
    )
}