import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import ParseDate from "../../tools/ParseDate"
import ParseTime from "../../tools/ParseTime"

export default function DashboardTable({teamID, type, history}) {
    const {t} = useTranslation()
    let navigate = useNavigate()

    const getStatusClass = (status) => {
        switch (status) {
            case "done":
                return "status-finalise"
            case "failed":
                return "status-erreur"
            case "error":
                return "status-erreur"
            case "in_progres":
                return "status-en-cours"
            default:
                return ""
        }
    }

    const redirectToProjectDashboard = (row) => {
        type === "licence" && navigate(`/neurowide-dashboard/team/${teamID}/${row.id_project}`)
    }

    return (
        <>
        <table className="tableau">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>{t("Task")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Project name")}</th>
                    <th>{t("Time/Date")}</th>
                    <th>{t("Creator")}</th>
                </tr>
            </thead>
            <tbody>
                {history.map((row, index) => (
                    <tr key={index} onClick={type === "licence" ? () => redirectToProjectDashboard(row) : undefined}>
                        <td title={row.id_project} style={{maxWidth: "11vw"}}>{row.id_project}</td>
                        <td title={row.step}>{row.step}</td>
                        <td title={row.status_code} className={getStatusClass(row.status_code)}>{row.status_code}</td>
                        <td title={row.project}>{row.project}</td>
                        <td title={`${ParseTime(row.execution_time)} - ${ParseDate(row.execution_time)}`}>{ParseTime(row.execution_time)} - {ParseDate(row.execution_time)}</td>
                        <td title={`${row.author.firstname} ${row.author.lastname}`} style={{maxWidth: "5vw"}}>{row.author.firstname} {row.author.lastname}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )
}