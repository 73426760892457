import {useState} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import {toast} from "react-toastify"
import {labelMap} from "../../constants/multilingual"
import Select from "react-select"

function AddTeamForm({sessionState, IS_MODAL, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation()
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [languages, setLanguages] = useState([])
    const [isLanguages, setIsLanguages] = useState(true)

    const transformLabelMap = (labelMap) => {
        return Object.entries(labelMap).map(([code, label]) => ({
            value: code,
            label: label,
        }))
    }

    const closeModal = () => {
        IS_MODAL(false)
    }

    const handleRequest = async (data) => {
        console.log("data :", data)
        if (languages.length > 1 || !data.administrator) {
            setIsLanguages(true)
            let languagesValues = []
            for (let element of languages) {
                languagesValues.push(element.value)
            }
            const bodyRequest = {
                name: data.name,
                licence_start_date: data.licenceStartDate,
                licence_end_date: data.licenceEndDate,
                licence_package: {url_label: data.urlLabel, url: data.url},
                email: data.administrator,
                max_users: parseInt(data.maxUsers),
                nb_credits: parseInt(data.nbCredits),
                max_duration: parseInt(data.maxDuration),
                max_transcription_per_project: parseInt(data.maxTranscription),
                max_translation_per_project: parseInt(data.maxTranslation),
                languages: languagesValues,
            }
            currentAuthSession().then((response) => {
                REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.post(API_TEAMS, bodyRequest, headersRequest)
            .then(() => {
                IS_MODAL(false)
                forceUpdate()
                toast.success(t("New license created"))
            }).catch((error) => {
                console.error("POST /teams :", error)
            })
        } else {
            setIsLanguages(languages.length <= 1 ? false : true)
        }
    }

    return (
        <>
        <form className="add-team-form">
            <div className="category">
                <h3>{t("LICENCE")}</h3>
                <div className="field">
                    <label for="">{t("Licence name")}*</label>
                    <input type="text" id="name" name="name" placeholder={t("Licence name")} {...register("name", {required: true, minLength: 2})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of creators")}*</label>
                    <input type="number" id="max-users" name="max-users" placeholder="Ex. 01" min="1" {...register("maxUsers", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Start")}*</label>
                    <input type="date" id="licence-start-date" name="licence-start-date" placeholder="" {...register("licenceStartDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("End")}*</label>
                    <input type="date" id="licence-end-date" name="licence-end-date" placeholder="" {...register("licenceEndDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">Team manager*</label>
                    <input type="email" id="administrator" name="administrator" placeholder="example@email.com" {...register("administrator", {required: true, pattern: /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/})}/>
                </div>
            </div>
            <div className="category">
                <h3>{t("CHARACTERISTICS")}</h3>
                <div className="field">
                    <label for="">{t("Credits")}*</label>
                    <input type="number" id="nb-credits" name="nb-credits" placeholder="Ex. 10" min="1" {...register("nbCredits", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Max audio / video duration")}*</label>
                    <input type="number" id="max-duration" name="max-duration" placeholder="Ex. 15 minutes" min="1" {...register("maxDuration", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of transcriptions allowed")}*</label>
                    <input type="number" id="max-transcription" name="max-transcription" placeholder="Ex. 03" min="1" {...register("maxTranscription", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of translations allowed")}*</label>
                    <input type="number" id="max-translation" name="max-translation" placeholder="Ex. 03" min="1" {...register("maxTranslation", {required: true})}/>
                </div>
                <label>{`${t("Team Languages")}*`}</label>
                <Select
                    placeholder={t("Select languages")}
                    isMulti
                    name="team-languages"
                    options={transformLabelMap(labelMap)}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isSearchable={false}
                    className={`basic-multi-select ${!isLanguages && "select-not-valid"}`}
                    classNamePrefix="select"
                    onChange={(value) => setLanguages(value || [])}
                />
                <h3>{t("package")}</h3>
                <div className="field">
                    <label for="">{t("Url label")}</label>
                    <input className={`${errors.urlLabel ? "not-valid" : ""}`} type="text" id="urlLabel" name="urlLabel" placeholder="Ex. Nolej"{...register("urlLabel", {minLength: 2})}/>
                </div>
                <div className="field">
                    <label for="">{t("Url")}</label>
                    <input className={`${errors.url ? "not-valid" : ""}`} type="text" id="url" name="url" placeholder="Ex. https://..." {...register("url", {pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/})}/>
                </div>
            </div>
            <div className="actions">
                <button className="cancel-btn" onClick={() => closeModal()}>{t("Cancel")}</button>
                <button className="added-btn" onClick={handleSubmit(handleRequest)}>{t("Added")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(AddTeamForm)