import {useState, useReducer, useEffect} from "react"
import {useParams} from "react-router-dom"
import axios from "axios"
import {API_PROJECTS} from "../constants/apiUrls"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import {SET_PROJECT_ID} from "../redux/actions/projectsActions"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import {WORKFLOW_IS_CONFIRMATION_MODAL} from "../redux/actions/worfklowActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import useProject from "../swrCustomHooks/useProject"
import useTries from "../swrCustomHooks/useTries"
import WorkflowHeader from "../sections/workflow-header/WorkflowHeader"
import LexiconsModal from "../sections/lexicons-modal/LexiconsModal"
import WorkflowContent from "../sections/workflow-content/WorkflowContent"
import LoadingComponent from "../components/loading-component/LoadingComponent"
import ConfirmationModal from "../components/confirmation-modal/ConfirmationModal"
import {useNavigate} from "react-router-dom"
import ScrollingMod from "../tools/ScrollingMod"

function Workflow({sessionState, lexiconsState, projectsState, workflowState, SET_PROJECT_ID, REFRESH_COGNITO_TOKEN, WORKFLOW_IS_CONFIRMATION_MODAL}) {
    const params = useParams()
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const {project, mutateProject, isLoadingProject, isErrorProject} = useProject(sessionState.idToken, params.projectID)
    const {tries, mutateTries, isLoadingTries, isErrorTries} = useTries(sessionState.idToken, params.projectID)
    const [workflowStep, setWorkflowStep] = useState()
    const [workflowNavigation, setWorkflowNavigation] = useState(-1)
    const [maxStep, setMaxStep] = useState(5)
    const [isPulling, setIsPulling] = useState(project?.ongoing_process)
    const [isUpdateWorkflowNavigation, setIsUpdateWorkflowNavigation] = useState(true)
    const [exportConfirmation, setExportConfirmation] = useState(false)
    ScrollingMod(isPulling || lexiconsState.isModal)
    let navigate = useNavigate()
    let intervalID

    const pullingTries = () => {
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.get(`${API_PROJECTS}/${projectsState.projectID}/tries`, headersRequest)
        .then((response) => {
            const trie = response.data[0]
            if (trie.status_code === "done") {
                isUpdateWorkflowNavigation && setWorkflowNavigation(workflowNavigation + 1)
                forceUpdate()
                setIsPulling(false)
                clearInterval(intervalID)
                intervalID = null
                setIsUpdateWorkflowNavigation(true)
            }
        })
        .catch((error) => {
            console.error("GET /projects/project/tries: ", error)
        })
    }

    useEffect(() => {
        sessionState.role === "admin" && navigate("/neurowide-dashboard")
        WORKFLOW_IS_CONFIRMATION_MODAL({modal: false, id: null})
        SET_PROJECT_ID(params.projectID)
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        if (isPulling) {
            window.scrollTo(0, 0)
            intervalID = setInterval(pullingTries, 10000)
        }
        mutateProject()
        mutateTries()
    }, [isRender, workflowStep, isPulling])

    if (isErrorProject || isErrorTries || isLoadingProject || isLoadingTries || project.message || tries.message) return <LoadingComponent/>
    return (
        <>
        <div className="VIEW WORKFLOW">
            {(workflowState.isConfirmationModal.modal && workflowState.isConfirmationModal.id === projectsState.projectID) && <ConfirmationModal type={"export"} setExportConfirmation={setExportConfirmation} modalMessage={`Your project will no longer be editable. Would you like to continue?`}/>}
            {lexiconsState.isModal && <><LexiconsModal type={"select"} forceUpdate={forceUpdate}/></>}
            <WorkflowHeader project={project} step={workflowStep} workflowNavigation={workflowNavigation} setWorkflowNavigation={setWorkflowNavigation} maxStep={maxStep}/>
            <WorkflowContent params={{project: project,
                tries: tries,
                workflowNavigation: workflowNavigation,
                setWorkflowStep: setWorkflowStep,
                setWorkflowNavigation: setWorkflowNavigation,
                setMaxStep: setMaxStep,
                isPulling: isPulling,
                setIsPulling: setIsPulling,
                setIsUpdateWorkflowNavigation: setIsUpdateWorkflowNavigation,
                forceUpdate: forceUpdate,
                exportConfirmation: exportConfirmation,
                setExportConfirmation: setExportConfirmation,
            }}/>
        </div>
        </>
    )
}

export default connect(globalSelector, {SET_PROJECT_ID, REFRESH_COGNITO_TOKEN, WORKFLOW_IS_CONFIRMATION_MODAL})(Workflow)