import * as types from "../redux-constants/projectsConstants"

export const IS_MODAL = (isModal) => ({
    type: types.IS_MODAL,
    payload: {
        isModal,
    }
})

export const PROJECTS_IS_CONFIRMATION_MODAL = (isConfirmationModal) => ({
    type: types.PROJECTS_IS_CONFIRMATION_MODAL,
    payload: {
        isConfirmationModal,
    }
})

export const SET_TAB = (tab) => ({
    type: types.SET_TAB,
    payload: {
        tab
    }
})

export const PROJECTS_SET_PAGE = (page) => ({
    type: types.PROJECTS_SET_PAGE,
    payload: {
        page
    }
})

export const SET_PROJECT_ID = (projectID) => ({
    type: types.SET_PROJECT_ID,
    payload: {
        projectID
    }
})