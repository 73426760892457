import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useProjects(tokenID, type, page) {
    const url = (type === "my-projects" ? `${API_PROJECTS}?public=false` : type === "recycle-bin" ? `${API_PROJECTS}?deleted=true` : type === "shared-projects" ? `${API_PROJECTS}?public=true` : API_PROJECTS)
    + (page ? `&limit=8&offset=${page === 1 ? 0 : ((page - 1) * 8)}` : "")

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: url, token: tokenID}, fetcher, {refreshInterval: 10000})

    return {
        projects: data,
        mutateProjects: mutate,
        isLoadingProjects: !error && !data,
        isErrorProjects: error
    }
}