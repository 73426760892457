import * as types from "../redux-constants/neurowideDashboardConstants"

const neurowideDashboard = {
    isModal: false,
    isConfirmationModal: {modal: false, id: null, action: null},
    teamPage: 1,
    projectPage: 1,
}

export default function NeurowideDashboardReducer(state=neurowideDashboard, action) {
    switch(action.type) {
        case types.IS_MODAL:
            return {
                isModal: action.payload.isModal,
                isConfirmationModal: state.isConfirmationModal,
                teamPage: state.teamPage,
                projectPage: state.projectPage,
            }
        case types.NW_DASHBOARD_IS_CONFIRMATION_MODAL:
            return {
                isModal: state.isModal,
                isConfirmationModal: action.payload.isConfirmationModal,
                teamPage: state.teamPage,
                projectPage: state.projectPage,
            }
        case types.NW_DASHBOARD_SET_TEAM_PAGE:
            return {
                isModal: state.isModal,
                isConfirmationModal: state.isConfirmationModal,
                teamPage: action.payload.teamPage,
                projectPage: state.projectPage,
            }
        case types.NW_DASHBOARD_SET_PROJECT_PAGE:
            return {
                isModal: state.isModal,
                isConfirmationModal: state.isConfirmationModal,
                teamPage: state.teamPage,
                projectPage: action.payload.projectPage,
            }
        default:
            return state
    }
}