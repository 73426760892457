import InformationPanel from "../../components/information-panel/InformationPanel"
import DashboardTable from "../../components/dashboard-table/DashboardTable"

export default function ProjectDashboardContent({projectOverview, projectHistory}) {
    return (
        <>
        <div className="SECTION PROJECT-DASHBOARD-CONTENT">
            <InformationPanel overview={projectOverview.project} type={"project"}/>
            <DashboardTable teamID={projectOverview.project.id_licence} type={"project"} history={projectHistory}/>
        </div>
        </>
    )
}