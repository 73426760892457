import {useEffect, useReducer} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useParams} from "react-router-dom"
import useProjectOverview from "../../swrCustomHooks/useProjectOverview"
import useProjectHistory from "../../swrCustomHooks/useProjectHistory"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import ProjectDashboardHeader from "../../sections/project-dashboard-header/ProjectDashboardHeader"
import ProjectDashboardContent from "../../sections/project-dashboard-content/ProjectDashboardContent"
import ProjectDashboardFooter from "../../sections/project-dashboard-footer/ProjectDashboardFooter"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

function ProjectDashboard({sessionState, neurowideDashboardState}) {
    let params = useParams()
    const {projectOverview, mutateProjectOverview, isLoadingProjectOverview, isErrorProjectOverview} = useProjectOverview(params.projectID, sessionState.idToken)
    const {projectHistory, mutateProjectHistory, isLoadingProjectHistory, isErrorProjectHistory} = useProjectHistory(params.projectID, sessionState.idToken, neurowideDashboardState.projectPage)

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateProjectOverview()
        mutateProjectHistory()
    }, [])

    if (isErrorProjectOverview || isErrorProjectHistory || isLoadingProjectOverview || isLoadingProjectHistory || projectOverview.message || !projectOverview || !projectHistory) return <LoadingComponent/>
    return (
        <>
        <RequireIsAdmin role={sessionState.role} redirect={true}>
            <div className="VIEW PROJECT-DASHBOARD">
                <ProjectDashboardHeader projectOverview={projectOverview}/>
                <ProjectDashboardContent projectOverview={projectOverview} projectHistory={projectHistory}/>
                <ProjectDashboardFooter projectID={params.projectID}/>
            </div>
        </RequireIsAdmin>
        </>
    )
}

export default connect(globalSelector)(ProjectDashboard)