import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/neurowideDashboardActions"
import {NW_DASHBOARD_IS_CONFIRMATION_MODAL} from "../../redux/actions/neurowideDashboardActions"
import editIcon from "../../assets/icon/edit.svg"
import trashIcon from "../../assets/icon/trash.svg"
import ParseDate from "../../tools/ParseDate"
import {useTranslation} from "react-i18next"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import arrowLeftIcon from "../../assets/icon/arrow-left.svg"
import {useNavigate} from "react-router-dom"

function TeamDashboardHeader({IS_MODAL, NW_DASHBOARD_IS_CONFIRMATION_MODAL, team}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const deleteTeam = () => {
        NW_DASHBOARD_IS_CONFIRMATION_MODAL({modal: true, id: team.id, action: "delete"})
    }

    const updateTeam = () => {
        IS_MODAL(true)
    }

    const previousPage = () => {
        navigate(-1)
    }

    if (!team) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION TEAM-DASHBOARD-HEADER">
            <span>
                <h1>{team.name}</h1>
                <img src={editIcon} alt="" onClick={updateTeam}/>
                <img src={trashIcon} alt="" onClick={deleteTeam}/>
                <p>{ParseDate(team.licence_start_date)} {t("at")} {ParseDate(team.licence_end_date)}</p>
            </span>
            <button className="previous-btn" onClick={previousPage} title={t("Previous page")}><img src={arrowLeftIcon} alt=""/></button>
        </div>
        </>
    )
}

export default connect(null, {IS_MODAL, NW_DASHBOARD_IS_CONFIRMATION_MODAL})(TeamDashboardHeader)