import i18next from "i18next"
export const labelMap = {
    "fr-FR": i18next.t("French"),
    "en-US": i18next.t("English"),
    "de-DE": i18next.t("German"),
    "it-IT": i18next.t("Italian"),
    "nl-NL": i18next.t("Dutch"),
    "pt-PT": i18next.t("Portuguese"),
    "pt-BR": i18next.t("Brazilian Portuguese"),
    "es-ES": i18next.t("Spanish"),
}